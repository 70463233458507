import React, {Component} from 'react';
import Table from '../../../components/Table';
import Nav from '../../../components/Nav';
import Modal from "../../../components/Modal";
import {CheckCircle, XCircle} from "react-feather";
import {Link} from "react-router-dom";
import Filter from "../../../components/filter";


class loan extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
        editModal: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav
                    name='loan'
                    buttons={[]}></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 '>
                    <div className='p-4'>
                        <Filter
                            filter={[
                                {
                                    label: 'Status',
                                    name: 'status_id',
                                    options: [
                                        {
                                            label: 'All',
                                            value: 0,
                                            default: true,
                                        },

                                        ...(window.global_status||[]).filter(d=>d.scope?.includes('ALL') || d.scope?.includes('LOANS')).map((d) => {
                                                return {
                                                    label: d.status_desc,
                                                    value: d.id,
                                                };
                                            }),
                                    ],
                                },
                                // {
                                //     label: 'Currency',
                                //     name: 'currency_id',
                                //     options: [
                                //         {
                                //             label: 'All',
                                //             value: 0,
                                //             default: true,
                                //         },
                                //
                                //         ...window.currency
                                //             .map((d) => {
                                //                 return {
                                //                     label: d.currency_name,
                                //                     value: d.id,
                                //                 };
                                //             }),
                                //     ],
                                // }
                            ]}
                            dateRange={['startdate', 'enddate']}
                            dateColumn={'created_at'}
                            dateRangeLabel='Date Created'
                            onChange={(filter) => {
                                let {query} = this.state;
                                this.setState({query: {...query, ...filter}});
                                setTimeout(() => {
                                    this.fetchloan();
                                }, 0);
                            }}
                        />
                        <Table
                            search={[]}
                            // sort="created_at"
                            // sortDirection={-1}
                            refreshAllowed={true}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: params});
                                setTimeout(() => {
                                    this.fetchloan();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                        <Modal
                            visible={this.state.editModal}
                            close={() => this.setState({editModal: false})}>
                            <h5 className="font-weight-bold" style={{margin: "10px"}}>{this.state.title}</h5>

                            <form onSubmit={this.editRequest}>

                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Name</span>
                                    <input className="form-control bg-light text-input" name="name" id="name"
                                           type="text" value={this.state.name} onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>
                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Email</span>
                                    <input className="form-control bg-light text-input" name="email" id="email"
                                           type="text" value={this.state.email} onChange={this.handleChange}
                                           style={{width: '400px'}}/>

                                </div>
                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Password</span>
                                    <input className="form-control bg-light text-input" type="text" name="password"
                                           id="password" value={this.state.password} onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>
                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Group</span>
                                    <select style={{width: '400px'}} className="form-control bg-light text-input"
                                            name="group_id" id="group_id" type="text" value={this.state.group_id}
                                            onChange={this.handleChange}>
                                        {window.groups?.map((d) => {
                                            return <option value={d.id}>{d.name}</option>;
                                        })}
                                    </select>
                                </div>


                                <div
                                    className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                                    <button
                                        className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Edit
                                    </button>
                                </div>
                            </form>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }

    fetchloan = () => {
        this.setState({table_loading: true});

        window.app
            .service('core/loan')
            .find({
                query: {
                    ...this.state.query,
                    agent_id: this.props.data?.agent_account_id_pk? undefined:this.props.data?.agent_id,
                    agent_account_id: this.props.data?.agent_account_id||this.props.data?.agent_account_id_pk,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    d['View'] = (
                        <Link
                            to={`/management/view/loan/${d.id}/details`}
                            className='btn btn-sm btn-round btn-outline-primary px-3'
                        >
                            View
                        </Link>
                    )
                    // d.interest_calculated = d.interest_earned+d.outstanding_setup_fees+d.outstanding_daily_interest+d.outstanding_penalty_fees;

                    let dNew = window.fxn.clean_filtered_columns(d, this.props.data)
                    return dNew
                });
                console.log("loan", response)

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
    editRequest = (event) => {
        event.preventDefault();
        let id = this.state.id;
        let data = {
            id: this.state.id,
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            group_id: this.state.group_id
        };
        if (!window.confirm("Are you sure that you want to Eit this user?")) return false;

        window.alert2.show({
            loader: true,
            title: 'Request Action Executing ...',
            buttons: false,
        });

        window.app
            .service('core/loan')
            .patch(id, data)
            .then((response) => {
                this.setState(response);

                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'loan updated Successfully',
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
                console.error(err);
            });

    };
    handleChange = (e) => {
        const {id, value} = e.target
        console.debug(e.target.id);
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }
}

export default loan;