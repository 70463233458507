import React, {Component} from 'react';
import start from '../../img/work.svg';
import Nav from "../../components/Nav";
import Table from "../../components/Table";

class Home extends Component {
    state = {
        tableData: {
            balances: [],
            users: [],
            transactions: [],
        },
    };

    render() {
        return (
            <div>
                <Nav name={''}></Nav>

                <div className='mx-auto'>
                    <div className='page-content px-3 position-relative'>
                        <div className='card py-4 px-5 mt-2 intro-card text-white my-5'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <h4 className='font-weight-bold intro-title'>
                                        Welcome Back !
                                    </h4>
                                    <div className='mt-3'>
                                        To get started, use the menu on your left. All the general
                                        summaries will be displayed on this page. Any new system
                                        updates will be displayed here.
                                    </div>
                                </div>
                                <div className='col-md-4 flex-row justify-content-center d-md-flex d-none'>
                                    <img src={start} alt='' className='intro-img'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className='row'>*/}
                    {/*    <div className='col-md-6'>*/}
                    {/*        <div className='mt-3 table-card border-0 card shado mx-3 '>*/}
                    {/*            <div className='p-4'>*/}


                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>

                <div className='p-4'></div>
            </div>
        );
    }

    componentDidMount() {
        // this.updateGraph("Users");
        // this.setState({ table_loading: true });
        // this.fetch('balances');
        // this.fetch('payment_requests');
    }

    fetch = (type) => {
        window.app
            .service('stats')
            .find({
                query: {
                    type: type,
                },
            })
            .then((response) => {
                // console.table(response)
                let u = {...this.state.tableData};
                u[type] = response;
                this.setState({tableData: u, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default Home;
