import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';

import './globals';
// pages
import Login from './pages/login';
import Home from './pages/dashboard/home';
import Menu from './components/Menu';
import Alert from './components/alert';
import Access from './components/accessManager';

import {getMenuList,NavRoutes} from './navUtils';

class App extends Component {
    state = {blur: false, authenticated: false};

    render() {
        return (
            <>
                <Alert
                    onRef={(ref) => {
                        window.alert2 = ref;
                    }}
                    toggle={(blur) => {
                        this.setState({blur});
                    }}/>
                <div
                    className={`page-blur page ${this.state.blur && 'blur-alert'}`}>
                    <Access permission='all'>
                        <Route path='/login' exact component={Login}/>
                    </Access>

                    {this.state.authenticated && (
                        <Portal hist={this.props.history}/>
                    )}
                </div>
                <div
                    className={`preloader-cover ${
                        this.state.loaded && 'hide-preloader'
                    }`}>
                    <div className='alert-loader px-3 my-3 d-flex flex-row justify-content-center'>
                        <div className='nest-parent'>
                            <div id='nest1'/>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    componentDidMount() {
        window.app
            .reAuthenticate()
            .then((response) => {
                // console.debug(response);
                this.fetchDefaults();

                let path = window.location.pathname;

                console.debug('path before ', path);

                if (path.includes('login') || path.includes('get-started'))
                    return false;
                console.debug('path after ', path);
            })
            .catch((error) => {
                console.debug(error);
                this.setState({loaded: true, authenticated: false});
                let p = window.location.pathname;
                if (!['/password-change', '/password-forgot'].includes(p))
                    this.props.history.push('/login');
            });
    }

    get = (model, callback, filter) => {
        window.app
            .service(model)
            .find({
                query: {
                    $limit: 250,
                    $skip: 0,
                    ...(filter ? filter : {}),
                },
            })
            .then((response) => {
                let data = [];
                response.data.map((d) => {
                    data[d.id] = d;
                });
                callback(data);
                this.verifyFetch();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    fetchDefaults = async () => {
        await  this.get(
            `prerequisites/admin-group-roles`,
            (response) => {
                let roles = {};
                response.map((d) => {
                    // console.debug(d)
                    if (!roles[d.admin_role.name]) roles[d.admin_role.name] = [];
                    roles[d.admin_role.name].push(d.admin_group.id);
                });

                window.roles = roles;
            },
            {
                group_id: window.user && window.user.admin.group_id,
            }
        );

        await this.get(`prerequisites/admin-groups`, (response) => {
            console.debug(response);
            window.groups = response;
        });
        await this.get(`prerequisites/admins`, (response) => {
            console.debug(response);
            window.admins = response;
        });
        await this.get(`core/currency`, (response) => {
            console.debug(response);
            window.currency = response;
        });
        await this.get(`core/status`, (response) => {
            window.global_status = response;
        });


        this.verifyFetch();
    };

    verifyFetch = () => {
        if (
            window.roles &&
            window.global_status &&
            window.currency &&
            window.groups &&
            window.admins
        ) {
            let loaded = false;
            if (localStorage.user) loaded = true;
            this.setState({authenticated: true, loaded});
        }
    };
}

class Portal extends Component {
    state = {};
    history = this.props.hist;

    render() {
        return (
            <>
                <div id='wrapper' className='pt-5 mt-md-0 pt-md-0 mt-2'>
                    <Menu menu={getMenuList()}/>
                    <div id='content-wrapper' className='d-flex flex-column bg-light'>
                        <NavRoutes/>
                        <div className='p-4'></div>
                        <div className='p-4 text-center'>
                            <span>{new Date().getFullYear()} © Access Bank & Vodacash. Powered by Turbo Groupe Investments. All Rights Reserved </span>
                            <br/>
                            <span>Language:&nbsp;<a href="#" onClick={()=>{
                                window.lg="EN";window.location.reload()
                            }}>English</a>&nbsp;|&nbsp;<a href="#" onClick={()=>{
                                window.lg="FR";window.location.reload()
                            }}>French</a>&nbsp;</span>
                        </div>
                    </div>

                </div>
            </>
        );
    }

    componentDidMount() {
        if (!localStorage.user) this.props.history.push('/login');
    }
}

export default withRouter(App);
