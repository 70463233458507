import React, {useEffect} from 'react';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "@mui/material/Card";
import Button from "@material-ui/core/Button";
import {chunk} from "lodash";
import Modal from "./Modal";

const  max_list_size =12
const RowPreview = props => {
    const {className, value,_obj, ...rest} = props;

    const [showRowPreview, setShowRowPreview] = React.useState(false);
    const [rowPreviewItem, setRowPreviewItem] = React.useState();


    useEffect(() => {

    }, []);


    return (
        <>
            <Button variant={"outlined"} className={"info"} onClick={() => {
                setRowPreviewItem(_obj)
                setShowRowPreview(true)
            }}>{value}</Button>

            <Modal
                visible={showRowPreview}
                width={Object.keys(rowPreviewItem||{}).length > max_list_size? 1000:500}
                close={() => {
                    setShowRowPreview(false)
                    setRowPreviewItem(null)
                }}>
                <div className='py-3 generate-csv-modal d-flex flex-column justify-content-between'>
                    {rowPreviewItem && (<div className='row p-3'>
                        {chunk(Object.entries(rowPreviewItem), (Object.keys(rowPreviewItem).length > max_list_size ? Math.ceil(Object.keys(rowPreviewItem).length/2) : Object.keys(rowPreviewItem).length)).map((xyz, index) => {
                            console.log("xyz",xyz)
                            return (
                                <div className={'col-md-'+(Object.keys(rowPreviewItem).length > max_list_size?6:12)}>
                                    <div className='card'>
                                        <table className='table table-striped table-hover'>
                                            <thead>
                                            <th>Field</th>
                                            <th>Data</th>
                                            </thead>
                                            {xyz.map(([k, v], i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className='text-capitalize'>
                                                            {k.replace(/\_/g, ' ')}
                                                        </td>
                                                        <td>{v}</td>
                                                    </tr>
                                                );
                                            })}

                                        </table>
                                    </div>

                                </div>
                            )
                        })}

                    </div>)}

                </div>
            </Modal>

        </>
    );


}

export default RowPreview;
